<template>
  <div class="flex flex-col h-full flex-grow overflow-hidden">
    <div :class="{'items-center justify-center' : !data}" class="flex w-full h-full">
      <app-highchart v-if="data"
        :ref="data.ref"
        :chart="data"
        :categories="brands"
        :colors="colors"
        :loading="loading"
        @changeActiveCategories="changeActiveCategories"
        customClass="pr-4 pb-6"
        customChartClass="h-full">
      </app-highchart>
      <template v-else-if="touch">
        <base-not-found />
      </template>
    </div>
    <others-modal v-if="othersModalData"
      :isOpen="isOpenOthersModal"
      :title="title"
      :categories="totalCategories"
      :colors="totalColors"
      :data="othersModalData"
      :hiddenSite="true"
      @close="closeOthersModal"
    ></others-modal>
  </div>
</template>

<script>
import AppHighchart from '@/components/charts/AppHighchart'
import OthersModal from '@/components/modalViews/OthersModal'
import moment from 'moment'
import { getAnalyzedMarket } from '@/services/analyzed-market/getAnalyzedMarket'
import { transformAnalyzedMarket } from '@/utils/transformDataToHighcharts'
import { getColors } from '@/utils/colors'
import { LIMIT, OTHER_COLOR } from '@/config/general'
import csvMixin from '@/mixins/csvMixin'
import highchartMixin from '@/mixins/highchartMixin'
import { deepClone } from '@/utils/general'

export default {
  name: 'analyzed-market',
  components: {
    AppHighchart,
    OthersModal
  },
  mixins: [csvMixin, highchartMixin],
  data: function () {
    return {
      hasOthers: false,
      isOpenOthersModal: false,
      othersModalData: null,
      touch: false,
      data: null,
      brands: [],
      colors: [],
      totalCategories: [],
      totalColors: [],
      activeCategories: [],
      totalData: [],
      csvLinks: [
        { ref: 'analyzedMarket', title: this.$t('app.analyzedMarket'), subtitle: this.$t('csv.file') }
      ]
    }
  },
  computed: {
    filterChangeCounter: function () {
      return this.$store.getters['filters/changeCounter']
    },
    selectedFilter1: function () {
      return this.$store.getters['filters/selectedDimensions'].filter1
    },
    selectedFilter2: function () {
      return this.$store.getters['filters/selectedDimensions'].filter2
    },
    selectedFilter3: function () {
      return this.$store.getters['filters/selectedDimensions'].filter3
    },
    selectedFilter4: function () {
      return this.$store.getters['filters/selectedDimensions'].filter4
    },
    selectedFilter5: function () {
      return this.$store.getters['filters/selectedDimensions'].filter5
    },
    groupId: function () {
      return this.$store.getters['filters/groupId']
    },
    categoryId: function () {
      return this.$store.getters['filters/categoryId']
    },
    startDate: function () {
      return this.$store.getters['filters/startDate']
    },
    endDate: function () {
      return this.$store.getters['filters/endDate']
    },
    minPrice: function () {
      return this.$store.getters['filters/minPrice']
    },
    maxPrice: function () {
      return this.$store.getters['filters/maxPrice']
    },
    currency: function () {
      return this.$store.getters['filters/currency']
    },
    brand: function () {
      return this.$store.getters['auth/user']?.nombreMarca
    },
    color: function () {
      return this.$store.getters['auth/user']?.color
    },
    csvChangeCounter: function () {
      return this.$store.getters['app/csvCounter']
    },
    selectedHealthIndexFilter: function () {
      return this.$store.getters['filters/healthIndexFilter'].selected
    },
    sites: function () {
      return this.$store.getters['filters/sites']
    },
    actualCategories: function () {
      return this.activeCategories
    },
    title: function () {
      return this.$t('app.analyzedMarket') + ' - ' + this.$t('app.others')
    },
    loading: function () {
      return this.$store.getters['app/loading']
    }
  },
  created: function () {
    this.$store.commit('app/setCsvLinks', this.csvLinks)
    this.downloadingData()
  },
  methods: {
    downloadingData: async function () {
      try {
        this.$store.commit('app/setLoading', true)
        await this.fetchAnalyzedMarket()
      } catch (error) {
        this.doFeedback()
      } finally {
        this.touch = true
        this.$store.commit('app/setLoading', false)
      }
    },
    fetchAnalyzedMarket: async function () {
      const groupId = this.groupId
      const categoryId = this.categoryId
      const startDate = moment(this.startDate).format('DD-MM-YYYY')
      const endDate = moment(this.endDate).format('DD-MM-YYYY')
      const body = {
        precioMin: this.minPrice,
        precioMax: this.maxPrice
      }

      if (this.selectedHealthIndexFilter && this.selectedHealthIndexFilter.length === 1) {
        body.healthIndex = this.selectedHealthIndexFilter[0].toString()
      }
      if (this.selectedFilter1 && this.selectedFilter1.length) {
        body.filtro1 = this.selectedFilter1
      }
      if (this.selectedFilter2 && this.selectedFilter2.length) {
        body.filtro2 = this.selectedFilter2
      }
      if (this.selectedFilter3 && this.selectedFilter3.length) {
        body.filtro3 = this.selectedFilter3
      }
      if (this.selectedFilter4 && this.selectedFilter4.length) {
        body.filtro4 = this.selectedFilter4
      }
      if (this.selectedFilter5 && this.selectedFilter5.length) {
        body.filtro5 = this.selectedFilter5
      }

      const data = await getAnalyzedMarket(groupId, categoryId, startDate, endDate, body)

      this.$store.commit('filters/setHealthIndexOK', data.healthIndexOK)
      this.$store.commit('filters/setHealthIndexKO', data.healthIndexKO)
      this.$store.commit('filters/setFilters', {
        filter1: data.filtro1,
        filter2: data.filtro2,
        filter3: data.filtro3,
        filter4: data.filtro4,
        filter5: data.filtro5
      })

      const sites = this.sites.map(site => site.name)
      const list = data.lista.filter(item => sites.includes(item.sitio))

      const transformData = transformAnalyzedMarket(list, LIMIT)
      const transformTotalData = transformAnalyzedMarket(list)

      let marketData = transformData.chart
      const marketTotalData = transformTotalData.chart
      const brands = transformData.brands
      const totalCategories = transformData.totalCategories

      const totalColors = this.getColors(totalCategories)
      const colors = this.getCustomColors(brands, totalCategories, totalColors)

      const othersLabel = this.$t('app.others')
      if (brands.includes(othersLabel)) {
        this.hasOthers = true
      } else {
        this.hasOthers = false
      }
      marketData = this.setChartsColors(marketData, brands, colors)
      marketData = this.setSeriesEvents(marketData)

      this.colors = colors
      this.totalColors = totalColors
      this.data = marketData
      this.totalData = marketTotalData
      this.brands = brands
      this.totalCategories = totalCategories

      this.$store.commit('filters/setResultsCounter', brands.length)
    },
    setSeriesEvents: function (data) {
      if (this.hasOthers) {
        const that = this
        data.options.plotOptions.series.events = {
          click: function (e) {
            if (this.name === that.$t('app.others')) {
              const x = e.point.category
              that.openOthersModal(x)
            }
          }
        }

        data.options.chart.events = {
          load: function () {
            const serie = this.series.find(s => s.name === that.$t('app.others'))
            if (serie) {
              serie.data.forEach(point => {
                if (point.graphic) {
                  point.graphic.attr({
                    cursor: 'pointer'
                  })
                }
              })
            }
          }
        }
      }

      return data
    },
    getColors: function (brands) {
      const length = brands.length
      const colors = getColors(length)
      const othersLabel = this.$t('app.others')
      if (brands.includes(othersLabel)) {
        const position = brands.findIndex(brand => brand === othersLabel)
        colors[position] = OTHER_COLOR
      }

      const color = this.color
      const brand = this.brand
      if (brands.includes(brand)) {
        const position = brands.findIndex(item => item === brand)
        colors[position] = color
      }

      return colors
    },
    getCustomColors: function (categories, totalCategories, totalColors) {
      return categories.map(category => {
        const index = totalCategories.findIndex(c => c === category)
        return totalColors[index]
      })
    },
    setChartsColors: function (chart, brands, colors) {
      const seriesBrands = chart.series.map(serie => serie.name)
      const c = this.getColorsByBrands(seriesBrands, brands, colors)
      chart.options.colors = c

      return chart
    },
    getColorsByBrands: function (seriesBrands, brands, colors) {
      return seriesBrands.map(brand => {
        const position = brands.findIndex(b => b === brand)
        return colors[position]
      })
    },
    changeActiveCategories: function (categories) {
      this.activeCategories = categories
    },
    openOthersModal: function (category) {
      const series = deepClone(this.data.series)
      const totalSeries = deepClone(this.totalData.series)
      const options = deepClone(this.data.options)
      const othersOptions = deepClone(this.totalData.options)
      const categories = options.xAxis.categories
      const categoryIndex = categories.findIndex(c => c === category)
      const othersCategories = othersOptions.xAxis.categories
      const othersCategoryIndex = othersCategories.findIndex(c => c === category)
      options.xAxis.categories = [category]
      othersOptions.xAxis.categories = [category]

      const serie = series.map(s => {
        s.data = [s.data[categoryIndex]]
        return s
      })

      const otherSerie = this.getOthersSerie(totalSeries, othersCategoryIndex, serie.filter(s => s.data[0]).map(s => s.name))
      const auxCategories = otherSerie.map(s => s.name)
      const colors = this.getColorsByBrands(auxCategories, this.totalCategories, this.totalColors)
      othersOptions.colors = colors
      const max = otherSerie.map(s => s.data[0] ? s.data[0] : 0).reduce((a, b) => a + b, 0)
      othersOptions.yAxis.max = max
      othersOptions.yAxis.tickPositioner = function () {
        const positions = this.tickPositions
        if (positions.length) {
          positions[positions.length - 1] = max
        }
        return positions
      }

      options.series = serie
      othersOptions.series = otherSerie

      this.othersModalData = [
        {
          ref: 'general',
          options: options,
          series: serie
        },
        {
          ref: 'others',
          options: othersOptions,
          series: otherSerie
        }
      ]
      this.isOpenOthersModal = true
    },
    getOthersSerie: function (series, categoryIndex, categories) {
      const otherSerie = series.filter(s => !categories.includes(s.name)).map(s => {
        s.data = [s.data[categoryIndex]]
        return s
      })

      return otherSerie.sort((a, b) => b.data[0] - a.data[0])
    },
    closeOthersModal: function () {
      this.isOpenOthersModal = false
    }
  },
  watch: {
    filterChangeCounter: function () {
      this.downloadingData()
    },
    csvChangeCounter: function () {
      const data = this.getCSVFromColumnChart(this.data.options, 'percent', null)
      this.downloadCsv(data, 'analyzed-market')
    }
  }
}
</script>
