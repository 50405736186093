import axiosInstance from '@/services/axiosInstance'

async function getAnalyzedMarket (groupId, categoryId, startDate, endDate, data) {
  const req = {
    url: `analyzedMarket/${groupId}/${categoryId}/${startDate}/${endDate}`,
    method: 'POST',
    data
  }

  const res = await axiosInstance(req)

  return res.data
}

export { getAnalyzedMarket }
